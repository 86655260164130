<template>
    <div class="portfolio">
        <div class="intro">
            <h1>My Work</h1>
            <p>Below are some examples of some projects I have worked.</p>
        </div>
        <div class="filters">
            <div class="title">Filters:</div>
            <div v-for="(filter, index) in filters" @click="selectFilter(filter)" :key="`filter-${index}`" class="option" :class="{'selected': selected.includes(filter)}">
                {{ filter }}
            </div>
        </div>
        <hr />
        <div class="projects">
            <div v-if="projects.length === 0" class="message">
                No projects loaded, come back later...
            </div>
            <div v-else-if="filteredProjects.length === 0 && projects.length !== 0" class="message">
                No projects match selected tags...
            </div>
            <div v-else v-for="(project, index) in filteredProjects" :key="`projects-${index}`" class="project">
                <div class="image">
                    <img :src="project.image" />
                </div>
                <div class="bottom">
                    <span class="name">{{ project.name }}</span>
                    <em class="tags">
                        <template v-for="(tag, tIndex) in project.tags">
                            <em :key="`${index}-tag-${tIndex}`" :class="{'selected': selected.includes(tag)}">{{ tag }}</em><template v-if="(project.tags.length - 1) !== tIndex">, </template>
                        </template>
                    </em>
                    <div class="description">
                        {{ description(project.description) }}
                    </div>
                </div>
                <a v-if="project.url" :href="project.url" target="_blank" class="cover"></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Portfolio',
    data () {
        return {
            selected: [],
            filters: ['Website', 'Web App', 'PHP', 'Javascript', 'SQL', 'Laravel', 'Vue', 'Flex', 'Grid'],
            projects: [
                {
                    name: 'Pool Craft',
                    image: '/images/poolcraft-720px.png',
                    url: 'https://poolcraft.co.nz/',
                    description: 'Auckland, New Zealand based pool building company. Website is built with VueJs.',
                    tags: ['Vue', 'Grid', 'Javascript', 'Website']
                },
                {
                    name: 'Lottin Point',
                    image: '/images/lottinpoint-720px.png',
                    url: '',
                    description: 'Web app was built for helping with managing accommodation at private camping ground. The front-end is built using VueJs, and back-end built using Laravel.',
                    tags: ['PHP', 'Javascript', 'SQL', 'Laravel', 'Vue', 'Flex', 'Grid', 'Web App']
                }
            ]
        }
    },
    computed: {
        filteredProjects: function () {
            return this.projects.filter(project => this.selected.length === 0 || this.hasSelectedTags(project))
        }
    },
    methods: {
        selectFilter (value) {
            if (this.selected.includes(value)) {
                this.selected = this.selected.filter(option => option !== value)
            } else {
                this.selected.push(value)
            }
        },
        hasASelectedTag (project) {
            if (this.selected.length === 0) return false

            const intersection = project.tags.filter(element => this.selected.includes(element))

            return intersection.length >= 1
        },
        hasSelectedTags (project) {
            if (this.selected.length === 0) return false

            return this.selected.filter(tag => project.tags.includes(tag)).length === this.selected.length
        },
        description (text, numberOfWords = 30) {
            const str = text.split(' ')

            if (str.length < numberOfWords) return str.join(' ')

            return text.split(' ').splice(0, numberOfWords).join(' ') + '...'
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.portfolio {
    .intro {
        padding: 20px 40px 0 40px;
    }

    > hr {
        margin: 30px 40px 40px;
        width: 30%;
        border-color: #9a9a9a;
    }

    .filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 30px 30px 40px;
        padding-bottom: 0;

        .title {
            width: 100%;
            padding: 0 10px
        }

        .option {
            border: 1px solid #ffffff;
            padding: 5px 20px;
            margin: 10px;
            border-radius: 50px;
            cursor: pointer;
            user-select: none;

            &:hover {
                border-color: $brand-color;
                color: $brand-color;
            }

            &.selected {
                border-color: $brand-color;
                background: $brand-color;
                color: #ffffff;
            }
        }
    }

    .projects {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: min-content;
        grid-gap: 40px;
        padding: 40px;
        padding-top: 0;

        @media screen and (min-width: 992px) {
            grid-template-columns: 1fr 1fr;
        }

        .project {
            position: relative;
            cursor: pointer;

            .image {
                img {
                    max-width: 100%;
                    height: auto;
                    display: block;
                }
            }

            .bottom {
                background: #24272782;
                border: 1px solid #24272782;
                height: 40px;
                line-height: 40px;
                padding: 0 10px;
                display: grid;
                grid-template-columns: min-content 1fr;
                grid-template-rows: min-content;

                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;

                transition: all 1s;

                .name {
                    white-space: nowrap;
                    font-weight: bold;
                }

                .tags {
                    overflow: hidden;
                    white-space: nowrap;
                    text-align: right;
                    text-overflow: ellipsis;
                    font-size: 0.8em;

                    .selected {
                        color: $brand-color;
                    }
                }

                .description {
                    visibility: hidden;
                    opacity: 0;
                    transition: visibility 0s, opacity 0.5s linear;
                    grid-column: span 2;
                    line-height: 1.5;
                }
            }

            &:hover {
                .bottom {
                    height: 100%;
                    background: #242727eb;
                    border-color: #ffffff;

                    .name {
                        font-size: 1.2em;
                    }

                    .tags {
                        font-size: 1em;
                    }

                    .description {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }

            .cover {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }

        > .message {
            font-size: 1.4rem;
        }
    }
}

</style>
